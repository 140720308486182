import "./nApp.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faPinterest } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="new_footer_area bg_color">
            <div className="new_footer_top">
                <div style={{padding: "2rem"}}>
                    <div className="row">
                        <div>
                            <div className="f_widget company_widget wow fadeInLeft" data-wow-delay="0.2s" style={{width: "100%", visibility: "visible", animationDelay: "0.2s", animationName: "fadeInLeft"}}>
                                <p>Don’t miss any safety updates!</p>
                                <form className="f_subscribe_two mailchimp">
                                    <input type="text" name="EMAIL" className="form-control memail" placeholder="Email" style={{borderRadius: "20px", marginRight: "10px"}}/>
                                    <button className="btn btn_get btn_get_two" type="submit">Subscribe</button>
                                    <p className="mchimp-errmessage" style={{display: "none"}}></p>
                                    <p className="mchimp-sucmessage" style={{display: "none"}}></p>
                                </form>
                            </div>
                        </div>
                        <div>
                            <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s" style={{visibility: "visible", animationDelay: "0.4s", animationName: "fadeInLeft"}}>
                                <h3 className="f-title f_600 t_color f_size_18">Our Locations</h3>
                                <ul className="list-unstyled f_list">
                                    <li>Grand Rapids</li>
                                    <li>131 Caledonia St NE,</li>
                                    <li>Grand Rapids, MI 49505</li>
                                    <li></li>
                                    <li>Austin</li>
                                    <li>3601 South Congress Ave,</li>
                                    <li>Suite D200, Austin, Texas 78704</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.6s" style={{visibility: "visible", animationDelay: "0.6s", animationName: "fadeInLeft"}}>
                                <h3 className="f-title f_600 t_color f_size_18">What we do</h3>
                                <ul className="list-unstyled f_list">
                                    <li>Integrations</li>
                                    <li>PayoffProtect</li>
                                    <li>Wire Fraud</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.8s" style={{visibility: "visible", animationDelay: "0.8s", animationName: "fadeInLeft"}}>
                                <h3 className="f-title f_600 t_color f_size_18">Team Solutions</h3>
                                <div className="f_social_icon row">
                                    <FontAwesomeIcon className="fa----icon" icon={faFacebook} />
                                    <FontAwesomeIcon className="fa----icon" icon={faTwitter} />
                                    <FontAwesomeIcon className="fa----icon" icon={faLinkedin} />
                                    <FontAwesomeIcon className="fa----icon" icon={faPinterest} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer_bg">
                    <div className="footer_bg_one"></div>
                    <div className="footer_bg_two"></div>
                </div>
            </div>
            <div className="footer_bottom">
                <div>
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-7">
                            <p className="mb-0 f_400" style={{textAlign: "center", width: "100vw"}}>© CertifID Inc.. 2023 All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;