import './rippler.css';

const Loader = () => {
    return (
        <div className="r-overlay">
            <h4>Verifying User</h4>
        <div className="lds-ripple">
            <div></div>
            <div></div>
        </div>
        </div>
    )
}

export default Loader;
