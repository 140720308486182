import { useState, useRef } from "react";
import "./form.css";

const PopForm = ({ formFilled, setFormFilled }) => {
  const [fileIsUploaded, setFileIsUploaded] = useState(false);
  const targetElementRef = useRef(null);

  const [formData, setFormData] = useState({
    files: [],
  });
  const [responseMessage, setResponseMessage] = useState("");

  const submitPopForm = async (event) => {
    event.preventDefault();

    const url = "https://certif.leruinvestments.click/popform";

    const formPayload = new FormData();
    formData.files.forEach((file) => {
      formPayload.append("files", file);
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formPayload,
      });

      const data = await response.text();

      if (response.ok) {
        setResponseMessage(data);
        setTimeout(() => setFormFilled(true), 2000);
        return
      } else {
        setResponseMessage(`Error occurred from server: ${response.statusText}`);
        setTimeout(() => {
          setResponseMessage(``);
        }, 4000);
        return
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setResponseMessage(`An error occurred: Please try again`);
      setTimeout(() => {
        setResponseMessage(``);
      }, 4000);
    }
  };

  const handleClick = () => {
    if (targetElementRef.current) {
      targetElementRef.current.click();
    }
  };

  const handleFileChange = (event) => {
  const selectedFiles = Array.from(event.target.files);

  const validFiles = selectedFiles.filter((file) => {
    return (
      file.type === "application/pdf" ||
      file.type === "application/epub+zip" ||
      file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
      file.type === "text/plain"
    );
  });

  const filesWithinSizeLimit = validFiles.filter((file) => {
    const maxSize = 3 * 1024 * 1024; // 3MB in bytes
    if (file.size <= maxSize) {
      return true;
    } else {
      console.error(`File "${file.name}" exceeds the size limit (3MB).`);
      setResponseMessage(`File exceeds the size limit (3MB). Try submitting another file.`);
      setTimeout(() => {
        setResponseMessage(``);
        window.location.reload()
      }, 3000);
      return false;
    }
  });

  setFormData((prevData) => ({
    ...prevData,
    files: filesWithinSizeLimit,
  }));

  setFileIsUploaded(true);
};


  return (
    <>
      {!formFilled ? (
        <form id="pop--form" onSubmit={submitPopForm}>
          <div id="pop--form--upload--section">
            <label htmlFor="pop--form--files"></label>
            <input
              style={{ display: "none" }}
              type="file"
              onChange={handleFileChange}
              name="pop--form--files"
              accept=".pdf, .epub"
              ref={targetElementRef}
              required
              multiple
            ></input>
          </div>
          <p style={{fontSize: "10px", color: "rgb(16, 39, 83)"}}>Max file upload size is 3MB -- (PDF, EPUB, DOCX and TXT)</p>
          <button className="upl--btn" onClick={handleClick}>
            Click Here To Upload
          </button>
          {fileIsUploaded && (
            <div className="p--f--files">
              {formData.files.map((popFormFiles, index) => {
                const fileSizeInMB = (
                  popFormFiles.size /
                  (1024 * 1024)
                ).toFixed(2);

                return (
                  <li key={index}>
                    <span
                      style={{ width: "80%" }}
                    >{`${popFormFiles.name}`}</span>
                    <span
                      style={{ width: "20%", textAlign: "right" }}
                    >{`${fileSizeInMB}MB`}</span>
                  </li>
                );
              })}
            </div>
          )}
          <input className="btn" type="submit" value={`submit`}></input>
          {responseMessage && (
            <p
              style={{
                color: "white",
                background: responseMessage.includes("error") ? "rgb(191, 7, 47)" : "rgb(16, 39, 83)",
                padding: ".5rem 1rem",
                fontSize: ".8rem",
                borderRadius: "0",
                animation: "fade-up 300ms linear"
              }}
            >
              {responseMessage}
            </p>
          )}
        </form>
      ) : (
        <>
          <p
            style={{
              color: "#656d80",
              background: "#fff",
              padding: "1rem",
              borderRadius: "0 1rem",
              border: "1px solid rgb(16, 39, 83)",
            }}
          >
          Thank you for submitting the document. <br/><br/>
            There is nothing else you need to do! Please close this window if you wish.
          </p>
        </>
      )}
    </> 
  );
};

export default PopForm;
