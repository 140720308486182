import './anim.css';

const Anim = () => {
	return(
<div>
  <div className="bg" />
  <div className="bg bg2" />
  <div className="bg bg3" />
</div>
);
}

export default Anim;
