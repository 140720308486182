import { useState } from "react";
import './ver.css';
import Logo from './../../logo.svg';
import PopForm from '../form/form';
import Anim from '../../anim'

const Success = () => {
  const [formFilled, setFormFilled] = useState(false);
  
  return (

    <div className="App">
      <div style={{ position: "absolute", top: "0", left: "0", background: "#fff", width: "100%", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center"}} className="flex-c" >
        <img id="logo" style={{ maxWidth: "400px", width: "100%", padding: "10px 20px", background: "#fff"}} src={Logo} alt="Logo" />
      </div>
      <div className="container">
      {!formFilled && <h2 style={{ color: "#102753" }}>Upload buyer representation agreement</h2>}
      <PopForm formFilled={formFilled} setFormFilled={setFormFilled} />
      </div> 
<Anim />
    </div>
  );
};
export default Success;
