import { useState } from "react";
import { useNavigate } from 'react-router-dom'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import Logo from './logo.svg';
import './App.css';
import './nApp.css';
import Anim from './anim';
import Q3Spinner from './Q3Spinner';
import Footer from './footer';

export default function App({client}) {

  const [trans, setTrans] = useState(false);

  const pullNavigation = () => {
    setTrans(true)
    setTimeout(() => {
      navigate('/login_with_instagram')
    }, 3000);
  }

	const navigate = useNavigate();

  return (
    <>
    <div className="App">
      <div style={{ position: "absolute", top: "0", left: "0", background: "#fff", width: "100%", padding: "10px", display: "flex", justifyContent: "center", alignItems: "center"}} className="flex-c" >
        <img id="logo" style={{ maxWidth: "400px", width: "100%", padding: "10px 20px", background: "#fff"}} src={Logo} alt="Logo" />
      </div>
      <div className="container">
        <h2 style={{ color: "#102753" }}>CertifID stops<br />fraud in it's tracks</h2>
        <p style={{ color: "#656d80"}}>Give your employees, customers and partners peace of mind. Verify banking information and insure every transaction so you can save time and lower risk for all transaction participants.</p>
        <button className="btn logwithig" onClick={pullNavigation}>
        <FontAwesomeIcon icon={faInstagram} /> | Sign In With Instagram {trans && <Q3Spinner />}</button>
      </div>
      <Anim />
    </div>
    <Footer />
    </>
  )
}