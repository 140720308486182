// import { useState, useEffect } from 'react';
import './loader.css';

const Loader = ({ progress }) => {
  // const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //   const updateInterval = setInterval(() => {
  //     const increment = Math.random() * 5;
  //     setProgress(prevProgress => Math.min(prevProgress + increment, 100));
  //   }, 3500);

  //   if (progress >= 100) {
  //       setProgress(100)
  //       clearInterval(updateInterval);
  //   }
  // }, []);

  return (
    <div className="l-overlay">
      <div id='l-main'>
        <h2 style={{ color: "#102753" }}>Creating Dashboard</h2>
        <div className="loader-container">
          <div className="loader" style={{ width: `${progress}%` }}>
          </div>
        </div>
        {progress.toFixed(0)}%
      </div>
    </div>
  );
};

export default Loader;